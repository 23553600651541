import React, { Component } from 'react';
// import ReactDom from 'react-dom';
import axios from 'axios';
import {NavLink} from 'react-router-dom';

// import bannerImg from './img/banner.jpg';
import bannerImg from './img/corceptual-systems-cbs.jpg';
import spinner from './img/technology.svg';

class HomePage extends Component {
    state={
        post:[]
    }
    // data call 
    componentDidMount(){
        axios.get("https://jsonplaceholder.typicode.com/posts")
        .then(response => {
            this.setState({post: response.data})
            // console.log(response);
        });
        // window.scrollTo(0, 0);
            
    }
    // componentDidUpdate = () => { ReactDom.findDOMNode(this).scrollIntoView(); }

  render() {
    //   const myPosts = this.state.post.map(pos => {
    //     return <li key={pos.id}>{pos.title}</li>;
    //   });
    return (  
        <div className="mainSection">
            <h1 className="pageHead"><span className="brand-color">Corceptual's</span> / Home</h1>
            <div className="home-banner bg-section">
            {/* <h1 className="tag-line">
                <span>Technology Development</span> 
                <span className="s-company">Service Company</span>
            </h1> */}
                <div className="banner-left">
                    <img src={bannerImg} alt="Technology Development Service Company" />
                </div>
                <div className="banner-right center">
                <h2>We work 24X7</h2>
                    <img src={spinner} alt="24X7" />
                    <p>Technology Development Service Company</p>
                    {/* <ul>
                        <li className="fontBold">Training &amp; Placement</li>
                        <li>Temenos T24</li>
                        <li>Flexcube</li>
                        <li>Finacle</li>
                        <li className="fontBold">Development</li>
                        <li>Responsive Web Design</li>
                        <li>Product Development</li>
                        <li>Enterprise Solution</li>
                    </ul> */}
                </div>
            </div>
             {/* <ul>
                        <li className="fontBold">Training &amp; Placement</li>
                        <li>Temenos T24</li>
                        <li>Flexcube</li>
                        <li>Finacle</li>
                        <li className="fontBold">Development</li>
                        <li>Responsive Web Design</li>
                        <li>Product Development</li>
                        <li>Enterprise Solution</li>
                    </ul> */}
            <h2><span>All Begins here</span></h2>
            <ul className="service-glimse">
                <li className="service-one">
                    <h3><span className="icon-headphones"></span></h3>
                    <div>ITES</div>
                </li>
                <li className="service-two">
                    <h3><span className="icon-message-square"></span></h3>
                    <div>Salesforce</div>
                </li>
                <li className="service-two">
                    <h3><span className="icon-unlock"></span></h3>
                    <div>Security</div>
                </li>
                <li className="service-two">
                    <h3><span className="icon-camera"></span></h3>
                    <div>Photography Services</div>
                </li>
                <li className="service-two">
                    <h3><span className="icon-globe"></span></h3>
                    <div>Web Development</div>
                </li>
                <li className="service-one">
                    <h3><span className="icon-cloud-rain"></span></h3>
                    <div>Digital Solutions</div>
                </li>
                <li className="service-two">
                    <h3><span className="icon-image"></span></h3>
                    <div>UI / UX</div>
                </li>
                <li className="service-two">
                    <h3><span className="icon-sliders"></span></h3>
                    <div>Software Solutions</div>
                </li>
            </ul>
            <div className="home-strategy">
                <h1>Our core strengths are...</h1>
                <ul className="our-strenght">
                    <li>—  Plan &amp; Strategy</li>
                    <li>—  Discover &amp; Develop</li>
                    <li>—  Optimitation &amp; Enhancement</li>
                    <li className="brand-color">—  <span className="fWeight">We Think Outside the Box</span></li>
                    <li>—  Clean &amp; Clear Architecture</li>
                    <li>—  Agile Process / Daily Scrum</li>
                    <li>—  Regular Status Reports</li>
                </ul>
            </div>
            <div className="more-about-us">
                <div className="meetat-coffee">
                    <code>"It's always GOOD to know" <NavLink to="/who-we-are">Who we are..</NavLink></code>
                </div>
            </div>
            {/* <div>
                {myPosts}
            </div> */}
        </div>
    );
  }
}

export default HomePage;


// import React from 'react';
// import axios from 'axios';

// const HomePage = () => {
//     state={
//         post:[]
//     }
//     return(
//         <div>Home content</div>
//     )
// }

// export default HomePage;