import React, { Component } from 'react';
import {NavLink} from 'react-router-dom';
import { connect } from 'react-redux';
import { getItems } from './actions/itemActions';
import PropTypes from 'prop-types';

import coffee from './img/meet-on-coffee.svg';
// import tanishq from './img/clients/tanishq.jpg';
// import toyota from './img/clients/toyota.jpg';
// import ipg from './img/clients/ipg.jpg';
// import ia from './img/clients/ia.jpg';

    class ContactPage extends Component {
    //  state = {
    //     openings:21
    // }

    componentDidMount() {
        this.props.getItems();
    }

    getDate = (date) => {
        return date.slice(0, 10);
    }
    
    render() {
        const { items } = this.props.item;
    return(
        <div className="mainSection">
            <h1 className="pageHead"><span className="brand-color">Corceptual's</span> / Careers</h1>
            
            <h2><span>Openings</span></h2>
            <ul className="job-card">
                {items.map(({ _id, title, Exp, company, location, description, date }) => (
                    <li key={_id}>
                        <div className="job-card-wrap">
                            <h5 className="job-title">{title}</h5>
                            <div className="job-company">{company}</div>
                            <div><span className="job-exp">{Exp}</span><span className="job-location">{location}</span></div>
                            <div className="job-description">{description}</div>
                            <div className="date-posted">Posted on <span className="colorDark">{this.getDate(date)}</span></div>
                            <div><button class="link-button">Apply now</button></div>
                        </div>
                    </li>       
                ))}
            </ul>
            
            <div className="more-about-us">
                 <h3 className="align-l">Know more about current openings</h3> 
                <div className="meetat-coffee">
                    <span className="coffee-holder"><img src={coffee} alt="Meet-us" /></span>
                    <code>"Keen to know more" <NavLink to="/contact">Let's meet for Coffee or beer..</NavLink></code>
                </div>
            </div>
        </div>
    )
    }
}

ContactPage.protoTypes = {
    getItems: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    item: state.item
    // return{
    //     openings:state.openings
    // }
})

// const mapDispatchToProps = (dispatch) => {
//     return {

//     }
// }
export default connect(mapStateToProps, { getItems })(ContactPage);