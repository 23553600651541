import axios from 'axios';
import { GET_ITEMS, ADD_ITEM, DELETE_ITEM, ITEMS_LOADING } from './types';

// export const getItems = () => {
//     return{
//         type:GET_ITEMS
//     }
// };

export const getItems = () => dispatch => {
    dispatch(setItemsLoading());
    axios
        .get('http://localhost:5000/api/jobs')
        .then(res =>
            dispatch({
                type: GET_ITEMS,
                payload: res.data
            })
        )
}

export const addJob = item => dispatch=> {
    axios
        .post('http://localhost:5000/api/jobs', item)
        .then(res => 
            dispatch({
                type: ADD_ITEM,
                payload: res.data
            })
        )
    // return {
    //     type: ADD_ITEM,
    //     payload: item
    // };
};

export const deleteItem = (id) => dispatch => {
    axios.delete(`http://localhost:5000/api/jobs/${id}`).then( res => 
          dispatch({
              type: DELETE_ITEM,
              payload: id
          })
    )
    // return {
    //     type: DELETE_ITEM,
    //     payload: id
    // };
};

export const setItemsLoading = () => {
    return {
        type: ITEMS_LOADING
    };
};
