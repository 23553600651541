import React from 'react';
import { SocialIcon } from 'react-social-icons';

const Footer = () => {
    return(
        <div className="siteFooter">
            <p>Copyright © 2018 Corceptual Systems</p>
            <div className="socialMedia">
                <SocialIcon url="https://plus.google.com/u/0/109078595591150080538" style={{ height: 40, width: 40 }} />
                <SocialIcon url="http://twitter.com/corceptual" style={{ height: 40, width: 40 }}/>
                <SocialIcon url="https://www.instagram.com/corceptualsystems/" style={{ height: 40, width: 40 }} />
                <SocialIcon url="https://www.linkedin.com/in/corceptualsystems" style={{ height: 40, width: 40 }}/>
            </div>
        </div>
    )
}

export default Footer;