import React from 'react';

import {NavLink} from 'react-router-dom';

import coffee from './img/meet-on-coffee.svg';

const ContactPage = () => {
    
    return(
        <div className="mainSection">
            <h1 className="pageHead"><span className="brand-color">Corceptual's</span> / Smart City</h1>
            <h2><span>Connecting Smart Cities</span></h2>
            {/* <ul className="service-glimse"> */}
            <p className="para">Smart City is an urban development vision to integrate multiple technologies and Internet of things (IoT) solutions in a secure fashion to manage a city’s assets</p>
            <p className="para">Our multi-technology, global solution approach gives you the flexibility to securely scale your smart city with plug and play connectivity. By adopting the right smart cities platform, you can create significant long-term value with new business models, revenue streams and improved return on investment</p>
            <div className="more-about-us">
            <h3 className="align-l">Know more about Smart cities</h3>
                <ol>
                    <li className="faqAnswersMulti"> - Multi-technology, global solutions that grow with you</li>
                    <li className="faqAnswersMulti"> - Secure foundation for your smart city transformation</li>
                    <li className="faqAnswersMulti"> - Plug-and-play connectivity with a global ecosystem of partners</li>
                </ol>
                <div className="meetat-coffee">
                    <span className="coffee-holder"><img src={coffee} alt="Meet-us" /></span>
                    <code>"Keen to know more" <NavLink to="/contact">Let's meet for Coffee or beer..</NavLink></code>
                </div>
                {/* <div className="meetat-coffee">
                    <code>"It's always GOOD to know" <NavLink to="/who-we-are">Who we are..</NavLink></code>
                </div> */}
            </div>
            {/* <div>
                {myPosts}
            </div> */}
        </div>
    )
}
export default ContactPage;