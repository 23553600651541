import React from 'react';
import {NavLink} from 'react-router-dom';

import coffee from './img/meet-on-coffee.svg';
import tanishq from './img/clients/tanishq.jpg';
import toyota from './img/clients/toyota.jpg';
import ipg from './img/clients/ipg.jpg';
import ia from './img/clients/ia.jpg';

const ContactPage = () => {
    
    return(
        <div className="mainSection">
            <h1 className="pageHead"><span className="brand-color">Corceptual's</span> / Web - iOS - Android</h1>
            <div className="alignCenter">
                <div className="bg-section">
                    <h4 className="withHelpText color-white">We believe in Native development</h4>
                    <span className="color-white webIcon">Web</span>
                    <span className="color-white appleIcon">iOS</span>
                    <span className="color-white androidIcon">Android</span>
                </div>
                <h2><span>Solutions</span></h2>
                <ul className="solutions">
                    <li>Mobile Solution</li>
                    <li>Web Solution</li>
                    <li>Enterprise Solution</li>
                    <li>Product Development</li>
                    <li>Android Development</li>
                    <li>iOS Development</li>
                    <li>Responsive Web Design</li>
                    <li>React / React Native</li>
                    <li>Big Data - MongoDB</li>
                    <li>MERN Stack</li>
                    <li>ML - TensorFlow</li>
                </ul>
                <h2><span>Our Clients</span></h2>
                <ul className="blockChainUses">
                    <li>
                        <div className="autoImageWrapper"><img src={tanishq} alt="Tanishq"/></div>
                        <h5>Tanishq</h5>
                        <div><NavLink to="/contact">know more</NavLink></div>
                    </li>
                    <li>
                        <div className="autoImageWrapper"><img src={toyota} alt="Toyota"/></div>
                        <h5>Toyota</h5> 
                        <div><NavLink to="/contact">know more</NavLink></div>
                    </li>
                    <li>
                        <div className="autoImageWrapper"><img src={ipg} alt="IPG"/></div>
                        <h5>Inter Public Group</h5>
                        <div><NavLink to="/contact">know more</NavLink></div>
                    </li>
                    <li>
                        <div className="autoImageWrapper"><img src={ia} alt="IA"/></div>
                        <h5>Interactive Avenues</h5>
                        <div><NavLink to="/contact">know more</NavLink></div>
                    </li>
                </ul>
            </div>
            <div className="more-about-us">
                 <h3 className="align-l">Know more about our core skills</h3> 
                <div className="meetat-coffee">
                    <span className="coffee-holder"><img src={coffee} alt="Meet-us" /></span>
                    <code>"Keen to know more" <NavLink to="/contact">Let's meet for Coffee or beer..</NavLink></code>
                </div>
            </div>
        </div>
    )
}
export default ContactPage;