import React, { Component } from 'react';
import { connect } from 'react-redux';
import { addJob } from './actions/itemActions';
import { Col, Row, Button, Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.css';

class AddJobModel extends Component{
    state={
        modal: false,
        title:''
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    onChange = e => {
        this.setState({[e.target.name]: e.target.value});
    }
    onSubmit = e => {
        e.preventDefault();
        const newJob = {
            title: this.state.title,
            company: this.state.company,
            description: this.state.description,
            Exp:this.state.Exp,
            location:this.state.location,
            Postedby:this.state.Postedby,
            Account:this.state.Account
        }
        this.props.addJob(newJob)

        this.toggle();
    }

    render() {
        return(
            <div>
                <Button
                    color="link"
                    onClick={this.toggle}
                >Add Job</Button>

                <div className="formModal"
                    // isOpen={this.state.modal}
                    toggle={this.toggle}>

                    <Modal 
                        isOpen={this.state.modal}
                        toggle={this.toggle}>
                        <ModalHeader toggle={this.toggle}>Add job</ModalHeader>
                        <ModalBody>
                        <Form onSubmit={this.onSubmit}>
                            
                            <Row form>
                                <Col md={6}>
                                <FormGroup>
                                    <Label for="jTitle">Job Title</Label>
                                    <Input id="jTitle" type="text" name="title" onChange={this.onChange} required/>
                                </FormGroup>
                                </Col>
                                <Col md={6}>
                                <FormGroup>
                                    <Label for="jcompany">Organisation / Client name</Label>
                                    <Input id="jcompany" type="text" name="company" onChange={this.onChange} required/>
                                </FormGroup>
                                </Col>
                            </Row>

                            <FormGroup>
                                <Label for="jdescription">Job Description</Label>
                                <Input id="jdescription" type="textarea" name="description" onChange={this.onChange} required/>
                            </FormGroup>
                            
                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="jExp">Experience (years)</Label>
                                        <Input type="text" name="Exp" id="jExp" placeholder="Experience" onChange={this.onChange} required/>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="jLocation">Job Location</Label>
                                        <Input type="text" name="location" id="jLocation" placeholder="Location" onChange={this.onChange} required/>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="jPostedBy">Posted by</Label>
                                        <Input type="text" name="Postedby" id="jPostedBy" placeholder="Posted by" onChange={this.onChange} required/>
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="jAccount">Account Type</Label>
                                        <Input type="text" name="Account" id="jAccount" placeholder="Free, Paid, Self" onChange={this.onChange} required/>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Button
                                block
                                color="dark"
                                style={{marginTop: '16px'}}
                            >Save</Button>
                        </Form>
                        </ModalBody>

                    </Modal>

                    {/* <h2>Add new job</h2>
                    <form onSubmit={this.onSubmit}>
                        <input type="text" name="title" id="titleId" onChange={this.onChange} />
                        <input type="text" name="description" id="titleId" onChange={this.onChange} />
                        
                        <button>Save</button>
                    </form> */}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    item: state.item
})

export default connect(mapStateToProps, {addJob})(AddJobModel);