import React from 'react';

import {NavLink} from 'react-router-dom';

import block from './img/blockc.svg';
import chain from './img/chain.svg';
import coffee from './img/meet-on-coffee.svg';
import supplyChainImg from './img/supplyChain.jpg';
import agriImg from './img/agriculture.jpg';
import iotImg from './img/IOT.jpg';
import selfCar from './img/self-driven-car.jpg';

const ContactPage = () => {
    
    return(
        <div className="mainSection">
            <h1 className="pageHead"><span className="brand-color">Corceptual's</span> / Blockchain</h1>
            <div className="about-us">
                <h3 className="subHead-long">"An InCorruptible Digital Ledger"</h3>
                <ul className="chain-of-blocks">
                    <li><span>Consensus</span>Distributed Trust</li>
                    <li><span>Security</span>Hacking impossible</li>
                    <li><span>Provenance</span>Trace an event back</li>
                    <li><span>Trust</span>Belief</li>
                </ul>
            </div>
            {/* <ul>
                <li>How Blockchain can transform YOU or INDIA</li>
            </ul> */}
            {/* <h3>BlockChain is the answer Now tell me the Question?</h3> */}
            <div className="alignCenter">Transaction approved by every Block of Chain </div>
            <div className="blockChainImg">
                <span className=""><img src={block} alt="BlockChain" /></span>
                <span className="chain"><img src={chain} alt="Chain" /></span>
            </div>
            <div className="alignCenter">
                <h4 className="withHelpText">How Blockchain can transform?</h4>
                <span className="brand-color">You / Your Business / Your Country</span>
                <ul className="blockChainUses">
                    <li>
                        <img src={supplyChainImg} alt="Supply chain"/>
                        <h5>Supplychain</h5>
                        Real time monitering and deliver genuine product
                    </li>
                    <li>
                        <img src={agriImg} alt="Agriculture"/>
                        <h5>Agriculture</h5> 
                        No loss on produces / Transparent transactions </li>
                    <li>
                        <img src={selfCar} alt="Self Driving Car"/>
                        <h5>Self Driving Car</h5>
                        On time pick and drop / no traffic issues
                    </li>
                    <li>
                        <img src={iotImg} alt="Internet of Things"/>
                        <h5>Internet of Things</h5>
                        Less power consumption
                    </li>
                </ul>
            </div>
            <div className="more-about-us">
                <h3 className="align-l">FAQ - Know more about BLOCKCHAIN</h3>
                <ol>
                    <li>BlockChain is the answer, Now tell me the Question?</li>
                    <li className="faqAnswers"> - IT's TOTAL JARGAN.</li>
                    <li>Spelling of BLOCKCHAIN is a bitcoin?</li>
                    <li className="faqAnswers"> - Absolutely No :)</li>
                    <li>Blockchain invented by Satoshi Nakamoto or a group called Satoshi Nakamoto?</li>
                    <li className="faqAnswersMulti"> - It was described in 1991 by team of researchers to timestamp digital documents.</li>
                    <li className="faqAnswersMulti"> - But the first BLOCKCHAIN was conceptualized by Satoshi Nakamoto or team in 2008.</li>
                    <li className="faqAnswersMulti"> - And design was implemented as a core component of the cryptocurrency bitcoin.</li>
                </ol>
                <div className="meetat-coffee">
                    <span className="coffee-holder"><img src={coffee} alt="Meet-us" /></span>
                    <code>"Keen to know more" <NavLink to="/contact">Let's meet for Coffee or beer..</NavLink></code>
                </div>
            </div>
        </div>
    )
}
export default ContactPage;