import React from 'react';
import {NavLink} from 'react-router-dom';

import logo from './img/corceptual-logo.png';

const Header = () => {
    function openTray(){
        document.querySelector('.siteHeader').classList.toggle("showTray"); 
    }
    return (      
        <div>
            <header className='siteHeader'>
            <div id="open-tray" onClick={openTray}></div>
            <div className="corceptualLogo"><img src={logo} alt="Corceptual Systems India pvt. ltd."/></div>
            <nav>
                <ul>
                    <li><NavLink to="/" exact>Home</NavLink></li>
                    <li className="new-item"><NavLink to="/smartcity">Smart City</NavLink></li>
                    <li><NavLink to="/service">Services</NavLink></li>
                    <li className="new-item"><NavLink to="/services/blockchain">Blockchain</NavLink></li>
                    <li><NavLink to="/CBS">Core Banking Software</NavLink></li>
                    <li><NavLink to="/web-iOS-Android">Web / iOS / Android</NavLink></li>

                    {/* <li><NavLink to="/services/software">Software</NavLink></li> */}
                    {/* <li><NavLink to={{
                        pathname: '/contact',
                        hash: '#submit',
                        search: '?quick-submit=true'
                    }}>Contact us</NavLink></li> */}
                </ul>
            </nav>
            
            <section className="siteNavigation">
                <ul>
                    <li><NavLink to="/who-we-are">Who we are?</NavLink></li>
                    {/* <li><NavLink to="/our-team">Our Team</NavLink></li> */}
                    <li><NavLink to="/careers">Careers</NavLink></li>
                    <li><NavLink to="/contact">Contact us</NavLink></li>
                </ul>
            </section>
            <div className="callSupport">
                <p>Click here to call</p>
                <div><a href="tel: 08043775869">080-4377-5869</a></div>
                <div><a href="tel: 7892611781">78926-11781</a></div>      
            </div>
            </header>
        </div>
    )
}
export default Header;