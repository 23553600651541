import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';

import './App.css';
import Header from './Header';
import Navigation from './Navigation';
import Footer from './Footer';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="AppWrapper">
          <Header/>
          <Navigation/>
          <Footer/>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
