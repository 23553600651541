import React from 'react';
import {NavLink} from 'react-router-dom';

const NoMatch = () => {
    return(
        <div className="mainSection">
            <h1 className="pageHead">Page not found! <span>Go to <NavLink to="/" exact>Home</NavLink></span></h1>
            <div>
                
            </div>
        </div>
    )
}

export default NoMatch;