import React from 'react';

import {NavLink} from 'react-router-dom';

import finacle from './img/finacle.jpg';
import flexcube from './img/flexcube.jpg';
import temenos from './img/temenos.jpg';
import coffee from './img/meet-on-coffee.svg';

const ContactPage = () => {
    
    return(
        <div className="mainSection">
            <h1 className="pageHead"><span className="brand-color">Corceptual's</span> / Core Banking Software</h1>
            <div className="alignCenter">
                {/* <h4 className="withHelpText">How Blockchain can transform?</h4>
                <span className="brand-color">You / Your Business / Your Country</span> */}
                <ul className="blockChainUses cbsSoftware">
                    <li>
                        <div className="autoImageWrapper"><img src={temenos} alt="Temenos T24"/></div>
                        <h5>T24</h5>
                        Retail Banking, Private Banking  &amp; Trade Finance etc.,
                        <div><NavLink to="/contact">know more</NavLink></div>
                    </li>
                    <li>
                        <div className="autoImageWrapper"><img src={flexcube} alt="Flexcube"/></div>
                        <h5>FlexCube</h5> 
                        Transform Your Retail Banking Strategy
                        <div><NavLink to="/contact">know more</NavLink></div>
                    </li>
                    <li>
                        <div className="autoImageWrapper"><img src={finacle} alt="Finacle"/></div>
                        <h5>Finacle</h5>
                        Explore better banking with us
                        <div><NavLink to="/contact">know more</NavLink></div>
                    </li>
                </ul>
            </div>
            <div className="more-about-us">
                 <h3 className="align-l">Know more about Core Banking Software</h3> 
                <div className="meetat-coffee">
                    <span className="coffee-holder"><img src={coffee} alt="Meet-us" /></span>
                    <code>"Keen to know more" <NavLink to="/contact">Let's meet for Coffee or beer..</NavLink></code>
                </div>
            </div>
        </div>
    )
}
export default ContactPage;