import React from 'react';
import {Switch, Route} from 'react-router-dom';

import HomePage from './HomePage';
import ContactPage from './ContactPage';
import AboutPage from './AboutPage';
import BlockChain from './BlockChain';
import Service from './Service';
import CBS from './cbs';
import Web from './Web';
import Careers from './CareersBeta';
import ManageJobs from './ManageJobs';
import SmartCity from './SmartCity';

// import Careers from './Careers';
import NoMatch from './NoMatch';
// import ScrollIntoView from './ScrollIntoView';
import ScrollToTop from './ScrollToTop';

const Navigation = () => {
    return ( 
        // <ScrollIntoView> 
        <ScrollToTop>  
            <Switch>
                <Route path="/" exact component={HomePage} />
                <Route path="/contact" exact component={ContactPage} />
                <Route path="/who-we-are" exact component={AboutPage} />
                <Route path="/services/blockchain" exact component={BlockChain} />
                <Route path="/service" exat component={Service} />
                <Route path="/CBS" exat component={CBS} />
                <Route path="/web-iOS-Android" exat component={Web} />
                <Route path="/careers" exat component={Careers} />
                <Route path="/manage-jobs" exat component={ManageJobs} />
                <Route path="/smartcity" exat component={SmartCity} />



                {/* <Route path="/careers" exat component={Careers} /> */}
                <Route component={NoMatch} />

                {/* <Route path="/" exact render={() => <h1>Home</h1>} />
                <Route path="/contact" render={() => <h2>Contact</h2> } /> */}
            </Switch>
             </ScrollToTop>
        // </ScrollIntoView>
    )
}

export default Navigation;