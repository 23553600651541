import React, { Component } from 'react';
// import {NavLink} from 'react-router-dom';
import { connect } from 'react-redux';
import { getItems, deleteItem  } from './actions/itemActions';
import PropTypes from 'prop-types';
import AddJobModel from './addJobModel';

    class ManageJobs extends Component {

    componentDidMount() {
        this.props.getItems();
    }

    onDeleteJob = (id) => {
        this.props.deleteItem(id);
    }

    getDate = (date) => {
        return date.slice(0, 10);
    }
    
    // () =>{
    //     this.setState(state =>({
    //         items: state.items.filter(item => item.id !== id)
    //     }));
    // }
    
    render() {
        const { items } = this.props.item;
    return(
        <div className="mainSection">
            <h1 className="pageHead"><span className="brand-color">Corceptual's</span> / Careers</h1>
            {/* <button
                onClick={ () => {
                    const title = prompt('Enter title');
                    if(title){
                        this.setState( state => ({
                            items: [...state.items, {id: "0975", title}]
                        }))
                    }
                }}
            >Add Jobs</button> */}
            
            <div>
                <h2><span>Openings</span><AddJobModel/></h2>
                
                <ul className="job-card">
                    {items.map(({ _id, title, Exp, company, location, description, date, Postedby, Account }) => (
                        <li key={_id}>
                            <div className="job-card-wrap admin-card">
                                <button className="cancel-button" onClick={this.onDeleteJob.bind(this, _id)}>X</button>
                                <h5 className="job-title">{title}</h5>
                                <div className="job-company">{company}</div>
                                <div><span className="job-exp">{Exp}</span><span className="job-location">{location}</span></div>
                                <div className="job-description">{description}</div>
                                <div className="date-posted">Posted on <span className="colorDark">{this.getDate(date)}</span></div>
                                <div className="postedby">Posted by <span className="colorDark">{Postedby}</span></div>
                                <div className={"postedby accStatus " + ((Account === 'Paid') ? 'colorGreen' :  (Account === 'Free') ? 'colorRed' : 'colorOrange')}>Account status <span className="colorDark">{Account}</span></div>

                                {/* <div className={"btn-group pull-right " + (this.props.showBulkActions ? 'show' : 'hidden')}> */}

                            </div>
                        </li>       
                    ))}
                </ul>
            </div>
        </div>
    )
    }
}

ManageJobs.protoTypes = {
    getItems: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    item: state.item
    // return{
    //     openings:state.openings
    // }
})

// const mapDispatchToProps = (dispatch) => {
//     return {

//     }
// }
export default connect(mapStateToProps, { getItems, deleteItem })(ManageJobs);