import React from 'react';

import {NavLink} from 'react-router-dom';

const ContactPage = () => {
    
    return(
        <div className="mainSection">
            <h1 className="pageHead"><span className="brand-color">Corceptual's</span> / Service</h1>
            <h2><span>Our Services</span></h2>
            {/* <ul className="service-glimse"> */}
            <ul className="service-glimse">
                <li className="service-one">
                    <h3><span className="icon-headphones"></span></h3>
                    <div>ITES</div>
                </li>
                <li className="service-two">
                    <h3><span className="icon-message-square"></span></h3>
                    <div>Salesforce</div>
                </li>
                <li className="service-two">
                    <h3><span className="icon-unlock"></span></h3>
                    <div>Security</div>
                </li>
                <li className="service-two">
                    <h3><span className="icon-camera"></span></h3>
                    <div>Photography Services</div>
                </li>
                <li className="service-two">
                    <h3><span className="icon-globe"></span></h3>
                    <div>Web Development</div>
                </li>
                <li className="service-one">
                    <h3><span className="icon-cloud-rain"></span></h3>
                    <div>Digital Solutions</div>
                </li>
                <li className="service-two">
                    <h3><span className="icon-image"></span></h3>
                    <div>UI / UX</div>
                </li>
                <li className="service-two">
                    <h3><span className="icon-sliders"></span></h3>
                    <div>Software Solutions</div>
                </li>
            </ul>
            <div className="more-about-us">
                <div className="meetat-coffee">
                    <code>"It's always GOOD to know" <NavLink to="/who-we-are">Who we are..</NavLink></code>
                </div>
            </div>
            {/* <div>
                {myPosts}
            </div> */}
        </div>
    )
}
export default ContactPage;