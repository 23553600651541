import React from 'react';
// import ReactDom from 'react-dom';

import {NavLink} from 'react-router-dom';

import coffee from './img/meet-on-coffee.svg';

// componentDidUpdate = () => { ReactDom.findDOMNode(this).scrollIntoView(); }

// class AboutPage extends Component(){
//     componentDidMount(){
//         // window.ScrollTo(0,0);
//     }
// }


const ContactPage = () => {
    
    return(
        <div className="mainSection">
            <h1 className="pageHead"><span className="brand-color">Corceptual's</span> / About us</h1>
            <div className="about-us">
                <h3 className="subHead">We are into..</h3>
                <span>IT Services</span>
                <span>IT Outsourcing</span>
                <span>Consulting</span>
                <span>UI / UX</span>
                <span>Design &amp; Development</span>
                <span>Digital</span>
            </div>
            <div className="start-up">
                <p>We believe in <span className="brand-color">end-to-end</span> solutions and nurture your <span className="brand-color">IDEAS</span> from seed to success.</p>
                <p>Start-ups or Established firms we custom care of each and every milestones you pass through.</p>
            </div>
            <div className="more-about-us">
                <h3 className="align-l">Know more about us</h3>
                <p>
                    Great, we are now the TEAM of 10+ and women folks are about 50% having expertize 
                    in multiple domains. We are Bangalore, INDIA based firm, established in Twenty Eighteen and love to MAKE IN INDIA.
                </p>
                <div className="meetat-coffee">
                    <span className="coffee-holder"><img src={coffee} alt="Meet-us" /></span>
                    <code>"Coffee is always a good IDEA" <NavLink to="/contact">Let's meet for it..</NavLink></code>
                </div>
            </div>
        </div>
    )
}
export default ContactPage;