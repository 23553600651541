import React from 'react';
import loaderImg from './img/loader.gif';

const ContactPage = () => {
    return(
        <div className="mainSection">
            <h1 className="pageHead"><span className="brand-color">Corceptual's</span> / Contact us</h1>
            <div className="gooDay">
                <h2>Good Day!</h2>
                {/* <div className=""><img src={gooDay} alt="Namaste"/></div> */}
                <p className="thanksMsg">Thank for visiting us.</p>
            </div>
            <div className="formLoader">
            <img src={loaderImg} alt="Loading..." />
            </div>
            <div className="contactForm">
                {/* <iframe title="Contact form" src="https://docs.google.com/forms/d/e/1FAIpQLSetvpy_8dkElwnO4ySokMHO--UvnUAJMBDeNRvwc6E8tyYeNw/viewform?embedded=true" width="640" height="864" frameborder="0" marginheight="0" marginwidth="0">Loading...</iframe> */}
                <iframe title="Contact form" src="https://docs.google.com/forms/d/e/1FAIpQLSetvpy_8dkElwnO4ySokMHO--UvnUAJMBDeNRvwc6E8tyYeNw/viewform?embedded=true" width="700" height="1153" frameborder="0" marginheight="0" marginwidth="0">Loading...</iframe>
                {/* <iframe title="Contact form" src="https://docs.google.com/forms/d/e/1FAIpQLSfP4xoOoJBcjDSo7vsVOEDEs8N8VRyi3W6nW0Z78vx2RAFCOw/viewform?embedded=true" width="700" height="520" frameborder="0" marginheight="0" marginwidth="0">Loading...</iframe> */}
            </div>
        </div>
    )
}

export default ContactPage;